(function($) {

    $.fn.modalForm = function(options) {
        var $modal = $('<div class="modal fade" id=""><div class="modal-dialog"><div class="modal-content"></div></div></div>');
        $modal.appendTo('body');
        $modal = $modal.modal({backdrop: true, show: false});
        var loader = '<div class="modal-body text-center"><i class="far fa-spinner-third fa-spin-fast fa-2x text-muted"></i><span class="h4"></span></div>';


        function setupSubmitButton() {
            var $submitButton = $modal.find('[type=submit]');
            var $form = $modal.find('form');
            $submitButton.data('loading-text', "<i class='far fa-spinner-third fa-spin-fast'></i> ");
            $form.on('submit', function() {
                if($form.parsley().isValid()) {
                    $submitButton.width($submitButton.width());
                    $submitButton.height($submitButton.height());
                    $submitButton.button('loading');
                    $modal.find('.modal-body,button,[type=button],input,.btn')
                        .addClass('disabled');
                    $modal.css('pointer-events', 'none');
                }
            });
        }

        function setToLoader() {
            $modal.find('.modal-content').html(loader);

        }

        function setModalContent(content) {
            $modal.find('.modal-content').html(content);
            $modal.find('.modal-content')
                .find('form')
                .append('<input name="return_url" type="hidden" value="' + window.location.href + '"/>');
            var isWide = $modal.find('.modal-body.wide').length > 0;
            if(isWide) {
                $modal.find('.modal-dialog').addClass('modal-wide');
            } else {
                $modal.find('.modal-dialog').removeClass('modal-wide');
            }
            setupSubmitButton();
            if(window.location.hash) {
                $modal.find('.modal-footer a').each(function() {
                    if(this.href.indexOf('?') == -1) {
                        this.href = this.href + '?tab=' + window.location.hash.substring(1);
                    } else {
                        this.href = this.href + '&tab=' + window.location.hash.substring(1);
                    }
                });
            }
            if(options && options.afterLoad) {
                options.afterLoad($modal[0]);
            }
            $(document).trigger('modal-form:loaded', {modal: $modal[0]});
        }

        function focus() {
            $modal.find('.modal-content input.form-control').first().focus();
        }

        function openModal(e) {
            e.preventDefault();
            var $this = $(e.target).closest('a'),
                url = $this.prop('href'),
                wide = $this.data('wide');
            if(wide) {
                $modal.find('.modal-dialog').addClass('modal-wide');
            } else {
                $modal.find('.modal-dialog').removeClass('modal-wide');
            }
            setToLoader();
            $modal.modal('show');
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'html'
            })
                .done(function(data) {
                    setModalContent(data); // load here
                    focus();
                })
                .fail(function() {
                    setModalContent('<i class="glyphicon glyphicon-info-sign"></i> Something went wrong, Please try again...');
                });
        }

        return this.each(function() {
            if(options.childSelector) {
                //Us bubble up from child selectors within this element
                return $(this).on('click', options.childSelector, openModal);
            }

            return $(this).on('click', openModal);
        });
    };

}(jQuery));
