//Jquery plugins
(function($) {
    $.fn.sortable = function() {

        return this.each(function() {

            var $this = $(this);
            var question = $this.data('question');

            new Sortable(this, {
                onEnd: function(evt) {
                    if(evt.oldIndex != evt.newIndex) {
                        $this.find('li').each(function(index, element) {
                            var newInputName = 'answers[' + question + '][options][' + index + ']';
                            $(element).find('input').attr('name', newInputName);
                        });
                    }
                },
                ghostClass: "sortable-ghost",
                sort: true,
                animation: 250,
                draggable: "li"
            });
        });
    };

}(jQuery));
