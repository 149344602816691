//Jquery plugin
(function ($) {
    Dropzone.autoDiscover = false;
    $.fn.uploadQuestion = function () {
        var token = $(".questions-form input[name=_token]").val();
        $('.btn-remove-file').on('click', function () {
            var id = $(this).data('id');
            $.getJSON(['/answers/upload', id, 'delete'].join('/'), function (response) {
            });
            $(this).closest('.list-group-item').first().remove();
        });
        this.each(function () {
            var $this = $(this), url = "/answers/upload",
                maxFiles = $this.data('max'),
                fieldName = $this.data('name'),
                acceptedFiles = $this.data('accept');

            $this.dropzone({
                url: url,
                maxFiles: maxFiles,
                acceptedFiles: acceptedFiles,
                success: function (data, uploadUrl) {
                    console.log(data, uploadUrl);
                    $this.after('<input name="' + fieldName + '" type="hidden" value="' + uploadUrl + '"/>');
                },
                sending: function (file, xhr, formData) {
                    // Will send the filesize along with the file as POST data.
                    formData.append("_token", token);
                }
            });

        });

        return this;
    };


}(jQuery));