(function ($) {

    function addToTimeText(text_string, unit, value) {
        var append_string = '';
        if (value > 0) {
            if (text_string.length > 0) {
                append_string += ' ';
            }
            if (value == 1) {
                append_string += value + ' ' + unit.slice(0, -1);
            }
            else {
                append_string += value + ' ' + unit;
            }
        }

        return text_string + append_string;
    }

    function saveTimeSinceQuestion() {
        var time_questions = $('.question-time-since');

        if (time_questions.length > 0) {
            time_questions.each(function () {
                var days = parseInt($(this).find('input[name=days]').val());
                var weeks = parseInt($(this).find('input[name=weeks]').val());
                var months = parseInt($(this).find('input[name=months]').val());
                var years = parseInt($(this).find('input[name=years]').val());
                var today = new Date();

                var total_days = 0;
                var time_since_text = '';

                if (days > 0) {
                    total_days = total_days + days;
                    time_since_text = addToTimeText(time_since_text, 'days', days);
                }
                if (weeks > 0) {
                    total_days = total_days + (7 * weeks);
                    time_since_text = addToTimeText(time_since_text, 'weeks', weeks);
                }
                if (months > 0) {
                    total_days = total_days + (30 * months);
                    time_since_text = addToTimeText(time_since_text, 'months', months);
                }
                if (years > 0) {
                    total_days = total_days + (365 * years);
                    time_since_text = addToTimeText(time_since_text, 'years', years);
                }

                $(this).find('.time-since-number').val(total_days);
                $(this).find('.time-since-text').val(time_since_text);
                $(this).find('.time-since-date').val(today.getUTCFullYear() + '-' + (today.getUTCMonth() + 1) + '-' + today.getUTCDate());

            });
        }

    }

    function onTimeSinceToggle() {
        $this = $(this);
        var field_to_show = $this.val();
        if (this.checked) {
            $this.closest('.question-time-since').find('input[name=' + field_to_show + ']').closest('.form-group').show();
        }
        else {
            $this.closest('.question-time-since').find('input[name=' + field_to_show + ']').closest('.form-group').hide();
        }
    }

    function timeSinceShowButtons() {
        $(".question-time-since input[type=checkbox]").on('change', onTimeSinceToggle);
    }

    function parseTimeSinceTextAnswer(answer) {
        var units = ['days', 'weeks', 'months', 'years'];
        var result = {};

        units.map(function (unit) {
            var place = answer.indexOf(unit); //plural
            var place2 = answer.indexOf(unit.slice(0, -1));
            var unit_value = 0;

            if (place != -1) {
                var value = answer.slice(0, place + unit.length);
                answer = answer.slice(place + unit.length, answer.length).trim();
                unit_value = parseInt(value);
            }
            else if (place2 != -1) {
                var value = answer.slice(0, place2 + unit.length - 1);
                answer = answer.slice(place2 + unit.length - 1, answer.length).trim();
                unit_value = parseInt(value);
            }

            result[unit] = unit_value;
        });

        return result;
    }

    function prepareTimeSinceQuestions() {
        var time_questions = $(this);

        if (time_questions.length > 0) {
            time_questions.each(function () {
                var saved_answer = $(this).find('.time-since-text').val();

                if (saved_answer.length > 0) {
                    $(this).find(".btn").removeClass("active");
                    var saved_values = parseTimeSinceTextAnswer(saved_answer);

                    $(this).find('.time-since-form .form-group').each(function () {
                        var field = $(this).find('input');
                        var name = field.attr('name');
                        if (Object.keys(saved_values).indexOf(name) != -1 && saved_values[name] > 0) {
                            field.val(saved_values[name]);
                            $(this).closest('.question-time-since').find('.time-since-btn input[value=' + name + ']')
                                .closest('label').addClass('active');
                        }
                        else {
                            $(this).hide();
                        }
                    });
                }
                else {
                    // $(this).find('.time-since-form .form-group').each(function () {
                    //     if ($(this).find('input').attr('name') != 'days') {
                    //         $(this).hide();
                    //     }
                    // });
                    $(".question-time-since input[type=checkbox]").each(onTimeSinceToggle);

                }
            });
        }
        timeSinceShowButtons();
        saveTimeSinceQuestion()
    }

    $.fn.timeSince = function () {
        return this.each(prepareTimeSinceQuestions);
    }

}(jQuery));

