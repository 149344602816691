//Jquery plugin
(function ($) {

    $.fn.checkboxQuestion = function () {

        this.each(function () {
            var $self = $(this);
            //Make sure all the wrapped checkboxes behave
            $self.find('button.btn:has(:checked)').addClass('active');
            $self.find('.panel-collapse:has(.panel-title:checked)').addClass('in');
            $self.find('label.btn:has(:checked)').addClass('active');

            $self.find('label input').each(function () {
                if (this.checked) {
                    $(this).parent().addClass('active');
                } else {
                    $(this).parent().removeClass('active');
                }
            });
        });

        return this;
    };


}(jQuery));