//Jquery plugin
(function ($) {

    $.fn.setupSelectBoxes = function () {
        this.each(function () {
            var context = this;
            $("select.supervisor", context).each(function () {
                var element = $(this);
                var placeholder = element.data('placeholder');
                var multiple = element.data('multiple');

                element.select2({
                    theme: "bootstrap",
                    placeholder: placeholder,
                    tags: multiple,
                    templateSelection: function (data, container) {
                        return data.text;
                    }
                });
            });

            $("select.select2", context).each(function () {
                var element = $(this);
                var placeholder = element.data('placeholder');
                var tags = element.data('tags');

                element.select2({
                    theme: "bootstrap",
                    placeholder: placeholder,
                    allowClear: true,
                    tags: tags,
                    // templateSelection: function (data, container) {
                    //     var parent = $(data.element).parent()[0];
                    //     if (!parent) {
                    //         return data.text;
                    //     }
                    //     if (parent.tagName === 'OPTGROUP') {
                    //         //Add group label if contained in a group
                    //         return parent.label + ': ' + data.text;
                    //     }
                    //     return data.text;
                    // }
                });
            });

            $("select.tags", context).each(function () {
                var element = $(this);
                var placeholder = element.data('placeholder');
                element.select2({
                    theme: "bootstrap",
                    placeholder: placeholder,
                    tags: true,
                    tokenSeparators: [',', ' ']
                });
            });


            function validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }

            $("select.ajax-select2", context).each(function () {
                var element = $(this);
                var url = element.data('url');
                var placeholder = element.data('placeholder');

                $(this).select2({
                    theme: "bootstrap",
                    minimumInputLength: 0,
                    placeholder: placeholder,
                    delay: 300,
                    ajax: {
                        type: "GET",
                        url: url,
                        dataType: 'json',
                        contentType: "application/json",
                        delay: 250,
                        processResults: function (data) {
                            console.log(data.data);
                            return {
                                results: data.data
                            };
                        }
                    }
                });
            });


//Select place and allow new places to be created
            $(".tags-ajax-select", context).each(function () {
                var element = $(this);
                var url = element.data('url');
                var placeholder = element.data('placeholder');
                $(this).select2({
                    theme: "bootstrap",
                    minimumInputLength: 0,
                    placeholder: placeholder,
                    delay: 300,
                    multiple: false,
                    tags: true,
                    ajax: {
                        type: "GET",
                        url: url,
                        dataType: 'json',
                        contentType: "application/json",
                        delay: 250,
                        processResults: function (data) {
                            return {
                                results: data.data
                            };
                        }

                    }
                });
            });

            $("select.ajax-place-select2", context).each(function () {
                var element = $(this);
                var url = element.data('url');
                var placeholder = element.data('placeholder');
                var tags = element.data('tags', false);
                var name = element.attr('name');

                $(this).select2({
                    minimumInputLength: 0,
                    placeholder: placeholder,
                    delay: 300,
                    tags: tags,
                    ajax: {
                        type: "GET",
                        url: url,
                        dataType: 'json',
                        contentType: "application/json",
                        delay: 250,
                        processResults: function (data) {
                            //add 'other' option in places dropdown
                            if (name == 'place_id') {
                                data.data.unshift({
                                    text: 'None',
                                    id: 'None'
                                });
                                data.data.unshift({
                                    text: 'Other',
                                    id: -1
                                });
                            }
                            return {
                                results: data.data
                            };
                        }
                    }
                });
            });

            $("select.emails", context).select2({
                theme: "bootstrap",
                tags: true,
                tokenSeparators: [',', ' ', ';'],
                closeOnSelect: false,
                selectOnClose: true,
                createTag: function (term, data) {
                    var value = term.term;
                    if (validateEmail(value)) {
                        return {
                            id: value,
                            text: value
                        };
                    }
                    return null;
                }
            });

        });
        return this;
    };


}(jQuery));
