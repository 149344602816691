/**
 * Revealed plugin hides and shows items based on the input value of the parent
 * e.g.
 * Include a data type:
 * data-reveal="[{
 * "pattern":"Example 1|Example 2",//Regex, Matched if the value is 'Example 1' or 'Example 2'
 * "selector":".example-group1"//Class or jquery selecter to show if matched
 * }
 * ,{
 *  "pattern":"example", //Regex, Matched if the value is 'example' can be any regex compatible with js string.match
 *  "selector":".example-group" //If matched show all elements with this class otherwise hide them
 *  }
 * ]"
 */
(function ($) {
    $.fn.revealed = function () {
        var activeSelectors = ":input,option";
        var firstLoad = true;

        function performAction(item, matched, el) {
            var clear = $(el).data('clear');
            var action = item.action ? item.action : 'show';
            var $target = $(item.selector);
            console.log(action, item.selector, matched);
            switch (action) {
                case "show":
                    if (matched) {
                        $target.show()
                            .find(activeSelectors)
                            .removeClass('no-validation')
                            .removeAttr('disabled');
                    } else {
                        setTimeout(function () {
                            hide($target, clear);
                        }, 50);
                    }

                    break;
                case "hide":
                    if (matched) {
                        hide($target, clear);
                    } else {
                        $target
                            .show()
                            .find(activeSelectors)
                            .removeClass('no-validation');
                    }

                    break;
                case "enable":
                    if (matched) {
                        $target
                            .find(activeSelectors)
                            .removeClass('no-validation')
                            .removeAttr("disabled");
                        $target.removeClass("disabled");
                    } else {
                        $target
                            .find(activeSelectors)
                            .addClass('no-validation')
                            .attr("disabled", true);
                        $target.addClass("disabled");
                    }
                    break;
                case "disable":
                    if (matched) {
                        $target
                            .find(activeSelectors)
                            .addClass('no-validation')
                            .attr("disabled", true);
                        $target.addClass("disabled");
                    } else {
                        $target
                            .find(activeSelectors)
                            .removeClass('no-validation')
                            .removeAttr("disabled");
                        $target.removeClass("disabled");
                    }
                    break;

            }
            return matched;
        }

        function onChange() {
            var $this = $(this);
            var data = $this.data('reveal');
            if (!data) {
                return;
            }
            if (typeof data === 'string') {
                data = JSON.parse(decodeURIComponent(data));
            }
            var targets = {};
            for (var i = data.length - 1; i >= 0; i--) {
                if (targets[data[i].selector]) {
                    continue;
                }
                targets[data[i].selector] = performAction(data[i], testPattern(data[i].pattern, this), this);
            }
        }

        function hide($target, clear) {
            if ($target.is(":hidden")) {
                return;
            }
            $target.hide()
                .find(activeSelectors)
                .addClass('no-validation')
                .attr('disabled', true)
                .removeAttr('checked')
                .each(hideChildren);
            if ($target && !firstLoad && $target.length > 0 && $target[0].tagName === 'OPTION') {
                $target.parent('select').prop('selectedIndex', 0).change();
            }
            if (clear === true) {
                $target
                    .find('label.btn')
                    .removeClass('active');
                $target
                    .find(activeSelectors)
                    .removeAttr('checked')
                    .trigger('change');
            }
        }

        function hideChildren() {
            var $this = $(this);
            var data = $this.data('enable');
            if (!data) {
                return;
            }

            if (typeof data === 'string') {
                data = JSON.parse(decodeURIComponent(data));
            }
            for (var i = data.length - 1; i >= 0; i--) {
                performAction(data[i], false, this);

            }
        }

        function testPattern(pattern, el) {
            var showTest = new RegExp(pattern, 'gmi');
            if ((el.type === 'checkbox' || el.type === 'radio') && !el.checked) {
                //We only want to perform the changes on checked
                return false;
            }
            if (pattern === null) {
                return value !== '';
            }

            return showTest.test(el.value);
        }


        this.each(function () {
            var $this = $(this);
            //Enable/Disable elements using data attributes
            $this
                .on('change keyup', onChange);
            $this.on('field:hidden', hideChildren);
            if (this.type === 'checkbox') {
                $this
                    .on('click', onChange);
            }
            // firstLoad = true;
            if (this.type === 'checkbox' || this.type === 'radio') {
                var hasAnswers = $('[name="' + this.name + '"]:checked').length > 0;
                if (hasAnswers && this.checked) {
                    //There are other radios checked so only fire on the checked ones
                    $this.each(onChange);
                } else if (!hasAnswers) {
                    //nothing checked fire on all of them
                    $this.each(onChange);
                }
            } else {
                $this.each(onChange);
            }
        });
        firstLoad = false;

        return this;
    };


}(jQuery));
