// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function() {
    };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while(length--) {
        method = methods[length];

        // Only stub undefined methods.
        if(!console[method]) {
            console[method] = noop;
        }
    }
}());


function scrollToTopView() {
    if($(window).scrollTop() > $(window).height() / 3) {
        if(!$('.scrollToTop').hasClass('showScrollTop')) {
            $('.scrollToTop').addClass('showScrollTop');
        }
    } else {
        $('.scrollToTop').removeClass('showScrollTop');
    }
}

function scrollToTarget(D) {
    if(D == 1) // Top of page
    {
        D = 0;
    } else if(D == 2) // Bottom of page
    {
        D = $(document).height();
    } else // Specific Bloc
    {
        D = $(D).offset().top;
    }

    $('html,body').animate({scrollTop: D}, 'slow');
}


function setup(context) {
    if(!context) {
        context = document;
    }
    $('.sequenced', context).sequenced();
    $(window).on('scroll', scrollToTopView);
    if($('.navbar-fixed-top', context).length > 0) {
        $(window).on('scroll', function() {
            if($(document).scrollTop() > 50) {
                $("nav .large").finish().fadeOut(600).css("display", "none");
                $("nav .small").finish().fadeIn(1500).css("display", "inline-block");
                $('nav').addClass('shrink');
            } else {
                $("nav .large").finish().fadeIn(1500).css("display", "inline-block");
                $("nav .small").finish().fadeOut(600).css("display", "none");
                $('nav').removeClass('shrink');
            }
        });
    }
    $('form.questions-form,.modal-content form,form.registration', context).parsley({
        excluded: '.datepicker, .date,.no-validation, :hidden',
        successClass: 'has-success',
        errorClass: 'has-error',
        classHandler: function(_el) {
            return _el.$element.closest('.error,tr,.grid-question-text,.form-group').first();
        }
    });
    // window.Parsley.on('form:error', function (e) {
    //     console.log(e);
    // });
    // window.Parsley.on('form:success', function () {
    //     $('form :input').garlic('destroy');
    // });


    //Setup star rating questions
    $('input.star-rating', context).rating({
        showClear: false,
        starCaptionClasses: {
            0.5: 'label label-danger',
            1: 'label label-danger',
            1.5: 'label label-warning',
            2: 'label label-warning',
            2.5: 'label label-info',
            3: 'label label-info',
            3.5: 'label label-primary',
            4: 'label label-primary',
            4.5: 'label label-success',
            5: 'label label-success',
            5.5: 'label label-success',
            6: 'label label-success',
            6.5: 'label label-success',
            7: 'label label-success',
            7.5: 'label label-success',
            8: 'label label-success',
            8.5: 'label label-success',
            9: 'label label-success',
            9.5: 'label label-success',
            10: 'label label-success'
        }
    });

    var isDownload = false;
    $('[type=submit]', context).on('click', function() {
        isDownload = $(this).is('.btn-download');
    });
    $(".form:not('.ajax')", context).on('submit', function() {
        if(!isDownload) {
            $('body').addClass('is-exiting');
            $('input[type=button],input[type=submit]', this).attr('disabled', 'disabled');
            $('a', this).attr('disabled', 'disabled');
        }
        return true;
    });

    $('.toggle-check', context).toggleVis();
    $("form.ajax", context).ajaxForm();
    $("form.loading-state", context).loadingState();
    $(context).setupSelectBoxes();

    $('.bound-check-parent', context).bindTo('.bound-check');

    $('.bound-date-parent', context).bindTo('.bound-date');

    $('.bound-item-parent', context).bindTo('.bound-item');


    $('.slideshow', context).each(function() {
        var options = $(this).data();
        $(this).slippry(options);
    });
    var isTouchDevice = 'ontouchstart' in document.documentElement;
    if(!isTouchDevice) {
        //Show tooltips on non-touch devices only
        $('.btn,[data-toggle="tooltip"]', context)
            .not('.input-group .btn')
            .tooltip({container: 'body'});
    }
    $('.date,[type=date]', context).each(function() {
        var $dateElement = $(this);

        // $dateElement.attr('type', 'text');
        var min = moment($dateElement.prop('min')).toDate();
        var max = moment($dateElement.prop('max')).toDate();
        $dateElement.data('ext', true);

        var picker = pikadayResponsive(this, {
            format: "dddd, MMMM Do YYYY",
            outputFormat: "YYYY-MM-DD",
            classes: 'form-control text-default',

            checkIfNativeDate: function() {
                return 'ontouchstart' in document.documentElement;
                // return true if native date field should be
                // used

            },
            pikadayOptions: {
                defaultDate: false,
                minDate: min,
                maxDate: max
            }
        });
        $dateElement.on('remoteChange', function(event, newValue) {
            $dateElement.data('ext', true);
            picker.setDate(newValue);
            $dateElement.removeData('ext');
        });
    });
    $('input.pikaday__display', context).attr('autocomplete', 'off');
    $('.questions-form', context).setupQuestionForm();
    // $('form.registration').garlic({
    //     destroy: false,
    //     onRetrieve: function (elem, retrievedValue) {
    //         // console.log('The retrieved value for ' + elem + ' is : ' + retrievedValue);
    //     }
    // });
    $("[data-reveal]", context).revealed();

    /*
     Generic are you sure dialog
     */
    $('form[name=delete_item]', context).on('submit', function() {
        return confirm("Are you sure you want to delete this item?");
    });

    /*
     Bind all bootstrap tooltips
     */
    $("[data-toggle=\"tooltip\"]", context).tooltip();
    $("[data-toggle=\"popover\"]", context).popover();
    //This closes the popover when its clicked away from
    $('body', context).on('click', function(e) {
        $('[data-toggle="popover"]').each(function() {
            if(!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });


    $('#choose-template', context).on('change', function() {
        if(!this.value) return;
        var data = $.parseJSON(this.value);
        if(!data) return;
        for(var key in data) {
            if(data.hasOwnProperty(key)) {
                $('#' + key).val(data[key]);
            }
        }
    });

    function checkRole() {
        var role = $('#role-select').val();
        if(role == 'Parent') {
            $('.parent-show').show();
            $('.parent-hide').hide();
        } else {
            $('.parent-show').hide();
            $('.parent-hide').show();
        }
    }

    $('#role-select').on('change', function() {
        checkRole();
    });

    function showOtherClubField() {
        var place = $('.user-place-field').val();

        if(place < 1 && !isNaN(parseInt(place))) {
            $('#other-club').show();
        } else {
            $('#other-club').hide();
        }
    }

    showOtherClubField();

    $('.user-place-field').on('change', function() {
        showOtherClubField();
    });

    //prevent onclick link, open mailto instead
    $(".mailto-people-link", context).on('click', function(e) {
        e.stopPropagation();
        document.location.href = 'mailto:' + $(this).text();
    });
    //
    // $("form a.btn-default", context).on('click', function (e) {
    //     $this = $(this);
    //     if ($this.text().indexOf('Clear') !== -1) {
    //         $this.attr("href", this.href + "?clear=1");
    //     }
    // });

    function getModalSortableItems(api_url, label) {
        $('.modal-sort-order .loader').show();

        $.ajax({
            type: "GET",
            url: api_url,
            dataType: "json"
        })
            .done(function(ret) {
                ret.data.sort(function(a, b) {
                    return a.sort_order - b.sort_order;
                });

                ret.data.map(function(elem) {
                    $('.modal-sortable').append("<li>" + elem[label] + "<input type='hidden' name='id[]' value='" + elem.id + "' /></li>");
                    return elem;
                });
            })
            .fail(function(ret) {
                console.log('fail');
            })
            .always(function(ret) {
                $(".modal-sortable").each(function() {
                    $this = $(this);
                    var sortable = new Sortable(this);
                });

                $('.modal-sort-order .loader').hide();

            });
    }

    $(".modal-sort-btn", context).on('click', function(e) {
        $('.modal-sort-order').modal('show');
        $('.modal-sortable').html('');

        var api_url = $(this).attr('modal-sort-item-url');
        var save_url = $(this).attr('modal-sort-save-url');
        var item_label = $(this).attr('modal-sort-item-label');

        $('#modal-sort-form').attr('action', save_url);

        getModalSortableItems(api_url, item_label);
    });

    $(".move-item-btn", context).on('click', function(e) {
        $('#modal-move-item input[name=item_id]').val($(this).data('item-id'));
        $('#modal-move-item').modal('show');
    });


    //disable save button in edit form if nothing is changed
    var editForm = $("#edit-event", context);
    editForm.find("input[type=submit]").attr('disabled', 'disabled');

    editForm.find("input[type=text], input[type=time], input[type=date], textarea")
        .on('change keyup', function(e) {
            editForm.find("input[type=submit]").removeAttr('disabled');
        });


    $('select[name=occurrence]', context).on('change', function() {
        document.location.href = $(this).val();
    });


    checkRole();

    $('#invite-register-form input', context).each(function() {
        var $this = $(this);
        if($this.attr('disabled')) {
            $this.parent().siblings('label').find('.required-sup').hide();
        }
    });

    $('#select-event-type', context).on('change', function() {
        document.location.href = $(this).val();
    });

    var eventTypeId = $('input[name=edit_event_type_id]', context).val();
    if(eventTypeId !== undefined) {
        if(parseInt(eventTypeId) === 0 || parseInt(eventTypeId) > 0) {
            var eventMessageTab = $('a[href="#event-email"]').tab('show');
            window.location.hash = eventMessageTab[0].hash;
            setTimeout(function() {
                $('a[href="#types"]').tab('show');
            }, 50);
        }
    }

    $('.table-chart', context).tableChart();

    $('.send-link-modal #module_id').on('change keyup', function() {
        var $form = $(this).parents('form').first();
        var userId = $form.find('#user_id').val();
        var moduleId = $form.find('#module_id').val();
        var queryString = $form.find('#vars').val();
        $('#copy-link-btn').addClass('disabled');
        $.get('/api/v1/modules/' + userId + '/' + moduleId + '/link/' + queryString).then(function(response) {
            $form.find('#module-link').val(response.data.link);
            $('#copy-link-btn').removeClass('disabled');
        });

    })
    new Clipboard('.btn-copy');

    $('.clipboard-btn').on('click', function() {
        var $temp = $('<input style="position: absolute;left:-1000px">');
        $("body").append($temp);
        $temp.val($(this).data('text')).select();
        document.execCommand("copy");
        $temp.remove();
    });
    // $('.save-pdf', context).savePdfButton();
    $('.submit-form').on('change', function() {
        $(this).closest('form').trigger('submit');
    });
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        var targetHash = this.hash;
        $('a.append-hash,.append-hash a').each(function() {
            var parts = this.href.split('#');
            this.href = parts[0] + targetHash;
        });
        appendReturnUrl();
    });
    appendReturnUrl();

    function appendReturnUrl() {
        $('input.return-url').val(window.location);
        $('a.return-url').each(function() {
            if(this.href.indexOf('?') > 0) {
                this.href = this.href + '&return_url=' + encodeURIComponent(window.location);
            } else {
                this.href = this.href + '?return_url=' + encodeURIComponent(window.location);
            }
        });
    }

    $('.nav-tabs', context).stickyTabs();
    $().simpleUpload();

    $('.fixed-table', context).fixedTable();
    $(document).on('shown.bs.tab', function() {
        $('.fixed-table').fixedTable();
    });

    $('#select-repetition').on('click', function() {
        var repetitionUrl = $('select[name=repetition]').val();
        if(repetitionUrl.length > 0) {
            document.location.href = repetitionUrl;
        }
    });
}

$(function() {
    jQuery.event.special.touchstart = {
        setup: function(_, ns, handle) {
            if(ns.indexOf("noPreventDefault")) {
                this.addEventListener("touchstart", handle, {passive: false});
            } else {
                this.addEventListener("touchstart", handle, {passive: true});
            }
        }
    };
    jQuery.event.special.touchmove = {
        setup: function(_, ns, handle) {
            if(ns.indexOf("noPreventDefault")) {
                this.addEventListener("touchmove", handle, {passive: false});
            } else {
                this.addEventListener("touchmove", handle, {passive: true});
            }
        }
    };
    jQuery.event.special.mousewheel = {
        setup: function(_, ns, handle) {
            if(ns.indexOf("noPreventDefault")) {
                this.addEventListener("mousewheel", handle, {passive: false});
            } else {
                this.addEventListener("mousewheel", handle, {passive: true});
            }
        }
    };
});

if(window.Turbolinks) {
    Turbolinks.SnapshotRenderer.prototype.assignNewBody = function() {
        if($(this.newBody).children().length === $(document.body).children().length) {
            var newBody = $(this.newBody).find('#content-wrapper').html();
            $(document.body).find('#content-wrapper').html(newBody);
            $('body').removeClass('modal-open').removeClass('form-loading');
            $('.modal-backdrop').remove();
            Turbolinks.clearCache();//Clear cache to prevent strange back behaviour
        } else {
            document.body = this.newBody;
        }
    };

}

document.addEventListener("turbolinks:before-cache", function() {
    $('.select2-hidden-accessible').removeClass('select2-hidden-accessible');
    $('.select2-container').remove();//Remove previous select2 elements before running them again

    $('.rating-active').each(function() {
        var $this = $(this);
        $this.find('input.star-rating').removeClass('hide').prependTo($this.parent());
        $this.remove();
    });
});

document.addEventListener("turbolinks:load", function() {
    setup(document.body);
    $('.modal-form-btn').modalForm({
        afterLoad: setup
    });
});
