//Jquery plugin
(function($) {

    $.fn.tableChart = function() {

        this.each(function() {
            var $this = $(this),
                labelFormatter = null,
                type = $this.data('type'),
                containerId = this.id + '-chart',
                ylabel = $this.data('ylabel'),
                title = $this.data('title'),
                pointWidth = $this.data('pointWidth'),
                height = null,
                width = null, showLabels = $this.data('show-labels');
            if($this.data('label-formatter')) {
                eval('labelFormatter = ' + $this.data('label-formatter'));
            }
            if($this.data('full-width')) {
                width = $(window).width();
            }
            if($this.data('height')) {
                height = $this.data('height');
            }

            $this.after('<div class="chart-container" id=' + containerId + '/>');
            $this.hide();
            if($this.data('label-ranges')) {
                labelFormatter = function() {
                    var ranges = JSON.parse(decodeURI($this.data('label-ranges')));
                    var value = this.y;
                    var name = this.series.singleSeriesName ? this.series.singleSeriesName : this.series.name;
                    var label = _.find(ranges[name], function(item) {
                        return value >= item.min && value <= item.max;
                    });
                    return label.text;
                }
            }
            var chartConfig = {
                legend: {
                    singleSeriesEnabled: type === 'line',
                    useHTML: true,
                    verticalAlign: 'top',
                    layout: 'horizontal',
                    x: 0,
                    y: 20
                },
                credits: {
                    enabled: false
                },

                data: {
                    table: this.id
                },
                plotOptions: {
                    series: {
                        pointWidth: pointWidth ? pointWidth : 5,
                        pointPadding: 5
                    },
                    column: {
                        pointPadding: 0,
                        borderWidth: 0,
                        groupPadding: 5,
                        shadow: false
                    },
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    },
                    line: {
                        dataLabels: {
                            enabled: showLabels,
                            align: 'left',
                            style: {
                                fontWeight: 'bold'
                            },
                            x: 3,
                            verticalAlign: 'middle',
                            overflow: 'justify',
                            crop: true
                        },
                    }
                },
                chart: {
                    width: width,
                    height: height,
                    type: type,
                },
                title: {
                    text: title
                },
                yAxis: {
                    allowDecimals: true,
                    title: {
                        text: ylabel ? ylabel : 'Score'
                    },

                }
            };
            if(labelFormatter) {
                chartConfig.plotOptions.line.dataLabels.formatter = labelFormatter;
            }
            Highcharts.chart(containerId, chartConfig);
        });
        // fix dimensions of chart in a hidden element
        $(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function(e) { // on tab selection event
            $("[data-highcharts-chart]", this).each(function() {
                var highChart = Highcharts.charts[$(this).data('highchartsChart')];
                var highChartCont = $(highChart.container).parent();
                highChart.setSize(highChartCont.width(), highChartCont.height());
                highChart.hasUserSize = undefined;
            });
            $(".highcharts-container").each(function() { // target each element with the .contains-chart class
                var chart = $(this).parent().highcharts(); // target the chart itself

                chart.reflow();// reflow that chart
            });

        });
        return this;
    };


}(jQuery));
