//Jquery plugin
(function ($) {

    $.fn.simpleUpload = function () {

        $(document).on('change', '.input-group :file', function () {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            if (numFiles > 1) {
                label = label + ' and ' + (numFiles - 1) + 'file(s)';
            }
            input.parents('.input-group').find('.btn-file-text').val(label);
        });

        return this;
    };


}(jQuery));