(function($) {


    $.fn.fixedTable = function(config) {


        return this.each(function() {
            var defaults = {
                columnCount: 1
            };
            var self = {};
            self.config = $.extend(config, defaults);


            /**
             * Get the combined width of the first cols up to colcount
             * @param colCount
             * @returns {number}
             */
            function getColWidth() {
                var colWidth = 0;
                var $cols;
                if(self.wrapper.find('.fixed-table-body thead th[rowspan]').length > 0) {
                    $cols = self.wrapper.find('.fixed-table-body thead tr:first th');
                } else {
                    $cols = self.wrapper.find('.fixed-table-body thead tr:last th');
                }
                for(var i = 0; i < self.config.columnCount; i++) {
                    colWidth += $($cols[i]).outerWidth();
                }

                return colWidth + 3;
            }

            function getDimensions() {
                var columnCount = self.wrapper.data('fixed-columns');
                if(columnCount) {
                    self.config.columnCount = columnCount;
                }
                self.colWidth = getColWidth();
                self.headerHeight = self.wrapper.find('.fixed-table-body thead').height();
                self.width = self.wrapper.find('.fixed-table-body').width();
            }

            function fixColumns() {
                self.left
                    .css({'width': self.colWidth});
                self.body
                    .css('margin-left', self.colWidth);
                self.body
                    .find('table')
                    .first()
                    .css('margin-left', -self.colWidth);
            }

            /**
             *
             * @param tableWrapper
             */
            function getElements(tableWrapper) {
                self.wrapper = $(tableWrapper);
                self.body = self.wrapper.find('.fixed-table-body');
                self.left = self.wrapper.find('.fixed-table-left');
                self.leftTable = self.left.find('table');
            }

            /**
             *
             * @param event
             */
            function onWheel(event) {
                if(event.originalEvent.wheelDeltaX !== 0) {
                    event.preventDefault();
                    var scroll = self.body.scrollLeft();
                    self.body.scrollLeft(scroll - event.originalEvent.wheelDeltaX);
                }
            }

            function init(tableWrapper) {
                getElements(tableWrapper);
                self.wrapper
                    .find('.fixed-table-section > table')
                    .removeAttr('style');
                getDimensions();
                if(self.width === 0) {
                    return;
                }
                self.wrapper
                    .find('.fixed-table-section > table')
                    .css({'min-width': self.width, 'width': self.width});
                fixColumns();
                self.left.on('mousewheel DOMMouseScroll wheel', onWheel);
            }

            init(this);
            return this;
        });

    };


}(jQuery));
