//Jquery plugin
(function ($) {

    $.fn.downloadQuestion = function () {

        this.each(function () {
            var $this = $(this);
            $this.on('click', function (e) {
                if ($this.prop('disabled') !== undefined) {
                    e.preventDefault();
                }
                else {
                    var question_id = $this.prop('question-id');

                    $('#question-download-' + question_id).val('1');
                }
            });
        });

        return this;
    };


}(jQuery));