var __slice = Array.prototype.slice;
(function ($) {
    var Sketch;
    $.fn.sketch = function () {
        var args, key, sketch;
        key = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
        if (this.length > 1) {
            $.error('Sketch.js can only be called on one element at a time.');
        }
        sketch = this.data('sketch');
        if (typeof key === 'string' && sketch) {
            if (sketch[key]) {
                if (typeof sketch[key] === 'function') {
                    return sketch[key].apply(sketch, args);
                } else if (args.length === 0) {
                    return sketch[key];
                } else if (args.length === 1) {
                    return sketch[key] = args[0];
                }
            } else {
                return $.error('Sketch.js did not recognize the given command.');
            }
        } else if (sketch) {
            return sketch;
        } else {
            this.data('sketch', new Sketch(this.get(0), key));
            return this;
        }
    };

    Sketch = (function () {
        function Sketch(el, opts) {
            this.el = el;
            this.canvas = $(el);
            this.context = el.getContext('2d');
            this.options = $.extend({
                toolLinks: true,
                defaultTool: 'marker',
                defaultColor: '#000000',
                defaultSize: 5
            }, opts);
            this.painting = false;
            window.hasTouch = false;
            this.color = this.options.defaultColor;
            this.size = this.options.defaultSize;
            this.tool = this.options.defaultTool;
            this.actions = [];
            this.action = [];
            this.answered = false;


            this.canvas.bind('click mousedown mouseup mousemove mouseleave mouseout touchstart touchmove touchend touchcancel', this.onEvent);
            $(document).on('click mousedown touchstart', this.resetScroll);
            if (this.options.toolLinks) {
                this.canvas.parents('.drawing-question').find("a").on('click', onToolClick);
            }
            $(document).on('keypress', function (e) {
                if (!window.activeSketch) return true;
                if (e.which === 90 && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    window.activeSketch.undo();
                }
            });
        }


        function onToolClick(e) {
            e.preventDefault();
            var $canvas, $this, key, sketch, _i, _len, _ref;
            $this = $(this);
            $canvas = $($this.attr('href'));
            sketch = $canvas.data('sketch');
            window.activeSketch = sketch;

            _ref = ['color', 'size', 'tool'];
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                key = _ref[_i];
                if ($this.attr("data-" + key)) {
                    sketch.set(key, $(this).attr("data-" + key));
                }
            }
            if ($(this).data('download')) {
                sketch.download($(this).data('download'));
            }
            if ($(this).data('clear')) {
                sketch.clear();
            }
            if ($(this).data('undo')) {
                sketch.undo();
            }
            return false;
        }


        function preventBodyScroll(on) {
            if (!window.hasTouch) return true;
            if (on) {
                return document.body.style.overflow = "hidden";
            }
            return document.body.style.overflow = "auto";
        }

        Sketch.prototype.resetScroll = function (e) {
            if (e.target != this.el) {
                preventBodyScroll(false);
            }
        }
        Sketch.prototype.clear = function (e) {
            this.context.clearRect(0, 0, this.canvas.width(), this.canvas.height());
            this.actions = [];

            return false;
        }

        Sketch.prototype.download = function (format) {
            var mime;
            format || (format = "png");
            if (format === "jpg") {
                format = "jpeg";
            }
            mime = "image/" + format;
            return this.el.toDataURL(mime);
        };
        Sketch.prototype.set = function (key, value) {
            this[key] = value;
            return this.canvas.trigger("sketch.change" + key, value);
        };
        Sketch.prototype.startPainting = function () {
            this.painting = true;

            preventBodyScroll(true);
            this.answered = true;
            return this.action = {
                tool: this.tool,
                color: this.color,
                size: parseFloat(this.size),
                events: []
            };
        };
        Sketch.prototype.undo = function () {
            this.actions.pop();

            return this.redraw();
        };

        Sketch.prototype.stopPainting = function () {
            if (this.action) {
                this.actions.push(this.action);
            }
            this.painting = false;
            this.action = null;
            preventBodyScroll(false);
            this.canvas.trigger("sketch.stopPainting", this);

            return this.redraw();
        };
        Sketch.prototype.onEvent = function (e) {
            if (!e) return true;
            if (e.type == 'touchstart') {
                window.hasTouch = true;
            }

            if (e.originalEvent && e.originalEvent.targetTouches && e.originalEvent.targetTouches.length > 0) {

                e.pageX = e.originalEvent.targetTouches[0].pageX;
                e.pageY = e.originalEvent.targetTouches[0].pageY;
            }
            $.sketch.tools[$(this).data('sketch').tool].onEvent.call($(this).data('sketch'), e);
            e.preventDefault();
            return false;
        };
        Sketch.prototype.redraw = function () {
            var sketch;
            this.el.width = this.canvas.width();
            this.context = this.el.getContext('2d');
            sketch = this;
            $.each(this.actions, function () {
                if (this.tool) {
                    return $.sketch.tools[this.tool].draw.call(sketch, this);
                }
            });
            if (this.painting && this.action) {
                return $.sketch.tools[this.action.tool].draw.call(sketch, this.action);
            }
        };
        return Sketch;
    })();
    $.sketch = {
        tools: {}
    };
    $.sketch.tools.marker = {
        onEvent: function (e) {
            switch (e.type) {
                case 'mousedown':
                case 'touchstart':
                    this.startPainting();
                    break;
                case 'mouseup':
                case 'mouseout':
                case 'mouseleave':
                case 'touchend':
                case 'touchcancel':
                    this.stopPainting();
            }
            if (this.painting) {
                this.action.events.push({
                    x: e.pageX - this.canvas.offset().left,
                    y: e.pageY - this.canvas.offset().top,
                    event: e.type
                });
                return this.redraw();
            }
        },
        draw: function (action) {
            var event, previous, _i, _len, _ref;
            this.context.lineJoin = "round";
            this.context.lineCap = "round";
            this.context.beginPath();
            this.context.moveTo(action.events[0].x, action.events[0].y);
            _ref = action.events;
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                event = _ref[_i];
                this.context.lineTo(event.x, event.y);
                previous = event;
            }
            this.context.strokeStyle = action.color;
            this.context.lineWidth = action.size;
            return this.context.stroke();
        }
    };

    return $.sketch.tools.eraser = {
        onEvent: function (e) {
            return $.sketch.tools.marker.onEvent.call(this, e);
        },
        draw: function (action) {
            var oldcomposite;
            oldcomposite = this.context.globalCompositeOperation;
            this.context.globalCompositeOperation = "copy";
            action.color = "rgba(0,0,0,0)";
            $.sketch.tools.marker.draw.call(this, action);
            return this.context.globalCompositeOperation = oldcomposite;
        }
    };
})(jQuery);

