"use strict";
(function($) {

    $.fn.advancedRange = function() {

        this.each(function() {
            var $input = $(this);
            var slider = $('<div class="advanced-slider"></div>');
            $input.before(slider);

            function filterPips(value, type) {
                if(!sizes[value]) {
                    return 1;
                }
                return sizes[value];
            }

            var range = JSON.parse(decodeURIComponent($input.data('range')));
            var orientation = $input.data('orientation');
            var pips = JSON.parse(decodeURIComponent($input.data('pips')));
            var labels = JSON.parse(decodeURIComponent($input.data('labels')));
            var sizes = JSON.parse(decodeURIComponent($input.data('sizes')));
            var tooltips = JSON.parse(decodeURIComponent($input.data('tooltips')));
            var start = $input.val() ? $input.val() : $input.data('start');
            pips.filter = filterPips;
            if(orientation == 'vertical') {
                slider.height($input.data('height'));
            }
            var options = {
                start: [parseFloat(start)],
                direction: $input.data('direction'),
                orientation: orientation,
                step: parseFloat(this.step),
                snap: !!$input.data('snap'),
                tooltips: [!!$input.data('tooltips')],
                prefix: $input.data('prefix'),
                decimals: parseInt($input.data('decimals')),
                range: range,
                pips: pips
            };
            if(tooltips) {
                options.tooltips = [wNumb({decimals: parseInt($input.data('decimals'))})];
            }
            $input.hide();
            $input.on('remoteChange', function(event, newValue) {
                $input.data('ext', true);
                slider[0].noUiSlider.set(parseFloat(newValue));
            });
            noUiSlider.create(slider[0], options);
            slider[0].noUiSlider.on('update', function(values, handle) {
                var value = values[handle];
                $input.val(value).change();
            });
            slider.find('.noUi-value').click(function() {
                var value = Number(this.getAttribute('data-value'));
                slider[0].noUiSlider.set(value);
            });
            slider.find('.noUi-value').each(function(index, element) {
                var val = $(element).data('value');

                if(typeof labels[val] === 'string' || labels[val] instanceof String) {
                    $(element).text(labels[val].replace(/\+/g, ' '));
                }
            });
        });

        return this;
    };

}(jQuery));
