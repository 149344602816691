(function($) {

    $.fn.setupQuestionForm = function() {

        return this.each(function() {
            var $self = $(this), $submitButton;
            // $self.garlic({
            //     destroy: true,
            //     onRetrieve: function (elem, retrievedValue) {
            //         // console.log('The retrieved value for ' + elem + ' is : ' + retrievedValue);
            //     }
            // });

            $self.softRequire(function() {
                $(document).trigger('beforeSubmitQuestions');

                return $self.parsley().isValid();
            });
            $self.checkboxQuestion();
            $self.find(".sortable").sortable();
            $self.find('.instant-poll-question').instantPoll();
            $self.find('.upload').uploadQuestion();
            $self.find('.grid-question').gridQuestion();
            $self.find('.scale-slider').scaleSlider();
            $self.find('.advanced-range').advancedRange();
            $self.find('.question-time-since').timeSince();
            $self.find('.repeatable-question').repeatableQuestion(function(context) {
                setup(context);
            });
            $self.find('.drawing-question').drawingQuestion();
            $self.find(".question-download-btn").downloadQuestion();
            $self.find("#hotspot-game").hotspotGame();


            function setupSubmitButton() {
                $submitButton = $self.find('button[type=submit]');
                $submitButton.each(function() {
                    $(this).data('loading-text', '<i class="far fa-spinner-third fa-spin-fast"></i> ' + $(this).text());
                });
            }

            function loadingStateOn() {
                $('.questions, a,button,[type=button],.btn,input,.btn-group')
                    .addClass('disabled');
                $('body').addClass('form-loading');
                $self.css('pointer-events', 'none');
            }

            window.Parsley.on('form:error', function() {
                loadingStateOff()
            });

            function loadingStateOff() {
                $('.questions, a,button,[type=button],input,.btn-group')
                    .removeClass('disabled');
                $('body').removeClass('form-loading');
                $self.css('pointer-events', 'inherit');
            }

            function onPreviousClick(e) {
                loadingStateOn();
            }

            function onSubmitClick(e) {
                loadingStateOn();
                e.preventDefault();
                if(typeof saveTimeSinceQuestion === 'function') {
                    saveTimeSinceQuestion();
                }

                var date_questions = $('.question-date');

                if(date_questions.length > 0) {
                    date_questions.each(function() {
                        $(this).find('input[type=hidden]').val($(this).find('input[type=date]').val());
                    });
                }

                $(document).trigger('beforeSubmitQuestions');
                $self.trigger('submit');
            }

            $self
                .on('focus', 'input[type=text],textarea', function() {
                    $('body').addClass('fix-fixed');
                })
                .on('blur', 'input[type=text],textarea', function() {
                    $('body').removeClass('fix-fixed');
                });
            $self.ajaxForm();
            setupSubmitButton();
            //Final check before submission
            $self.find("button[type=submit]").on('click', onSubmitClick);
            $self.find("#previous-button").on('click', onPreviousClick);
        });

    };

}(jQuery));
