//Jquery plugin
(function ($) {

    $.fn.loadingState = function () {

        this.each(function () {
            var $submitButton, $self = $(this);

            function setupSubmitButton() {
                $submitButton = $self.find('button[type=submit]');
                $submitButton.each(function () {
                    $(this).data('loading-text', '<i class="far fa-spinner-third fa-spin-fast"></i> ');
                });
            }

            function loadingStateOn() {
                $self.find('button').each(function () {
                    $(this).width($(this).width());
                    $(this).height($(this).height());
                })
                $submitButton.button('loading');
                $('body').addClass('form-loading');

                $self.find(' a,button,[type=button],.btn,input,.btn-group')
                    .addClass('disabled');

                $self.css('pointer-events', 'none');

            }

            window.Parsley.on('form:error', function () {
                loadingStateOff();
            });

            function loadingStateOff() {
                $submitButton.button('reset');
                $('body').removeClass('form-loading');

                $self.find('a,button,[type=button],input,.btn-group')
                    .removeClass('disabled');
                $self.css('pointer-events', 'inherit');
            }

            setupSubmitButton();
            $self.on('submit', function () {
                loadingStateOn()
            });
        });

        return this;
    };


}(jQuery));