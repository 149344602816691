(function($) {
    var loadedImageMaps = [];
    var currentImageMapIndex = 0;
    var hotspotTotalPoints = 0;
    var hotspotState = 'active';
    var hotspotPointReference = {
        1: 'bronze-badge',
        3: 'silver-badge',
        6: 'gold-badge'
    };

    var checkClickedArea = function(e) {
        var clickedArea = e.target.tagName;
        var correctScreen = $('#hotspot-correct');

        var image = loadedImageMaps[currentImageMapIndex].image;
        var target = loadedImageMaps[currentImageMapIndex].target;
        var a = document.getElementById("hotspot-target");

        currentImageMapIndex++;

        if(currentImageMapIndex < loadedImageMaps.length) {
            if(clickedArea != 'svg') {
                var badge = correctScreen.data('correct-image');

                hotspotTotalPoints += 1; //add point

                correctScreen.find('img').attr('src', badge);
                correctScreen.fadeIn(100).delay(500).fadeOut(100);
            } else {
                var incorrectScreen = $('#hotspot-incorrect');

                incorrectScreen.fadeIn(100);

                deactivateScreen();
            }

            getNewImageMap(image, target, a)
        } else {
            showBadge(correctScreen);
        }
    };

    function showBadge(correctScreen) {
        //reached end of image map array
        var hotspot = $('#hotspot-game');
        hotspot.find('#hotspot-total').val(hotspotTotalPoints);

        hotspot.find('#hotspot-target').hide();
        hotspot.find('#hotspot-image').fadeTo('fast', 0.5, function() {
            $('.task-buttons').show()
        });
        hotspotState = 'timedout';

        for(var i in hotspotPointReference) {
            if(i <= hotspotTotalPoints) {
                badge = correctScreen.data(hotspotPointReference[i]);
            }
        }

        correctScreen.find('img').attr('src', badge);
        correctScreen.fadeIn(100);
    }

    function deactivateScreen() {
        var hotspot = $('#hotspot-game');

        hotspot.find('#hotspot-target').hide();
        hotspot.find('#hotspot-image').fadeTo('fast', 0.5);
    }

    function activateScreen() {
        var hotspot = $('#hotspot-game');

        hotspot.find('#hotspot-image').fadeTo('fast', 1, function() {
            hotspot.find('#hotspot-target').show();
        });
    }

    function getNewImageMap(image, target, svgElement) {
        var loadedImage = '';
        var hotspotImage = $('#hotspot-image');

        svgElement.classList.add('not-visible');
        hotspotImage.fadeTo('fast', 0.6);

        axios.get(image).then(function(response) {
            loadedImage = image;
            axios.get(target).then(function(response) {
                hotspotImage.attr('src', loadedImage)
                    .fadeTo('fast', 1, function() {
                        svgElement.classList.remove('not-visible');
                    });

                var svgDoc = svgElement.contentDocument;

                svgElement.innerHTML = response.data;

                svg = svgElement.getElementsByTagName("svg");

                svg[0].addEventListener("click", checkClickedArea, false);

                return svg[0].children.length;
            })
        })
    }

    function formatForTimer(time, timeout) {
        var totalSeconds = timeout - (Math.floor(time / 1000))
        var minutes = Math.floor(totalSeconds / 60);
        var seconds = totalSeconds - minutes * 60;

        var secondsText = String(seconds)
        var minutesText = String(minutes)
        secondsText = secondsText.length > 1 ? secondsText : '0' + secondsText
        minutesText = minutesText.length > 1 ? minutesText : '0' + minutesText

        return minutesText + ':' + secondsText
    }

    $.fn.hotspotGame = function() {
        return this.each(function() {

            if(!axios) {
                console.log('Axios required for hotspot game');
                return;
            }

            var hotspot = $(this);
            var firstImage = hotspot.find("#hotspot-image").attr('src')
            if(!firstImage) {
                return;
            }
            var wrapper = hotspot.parent();
            var width = wrapper.width();
            var targetPath = hotspot.data('target-path');

            loadedImageMaps = hotspot.data('image-maps');
            currentImageMapIndex = 0;
            hotspotTotalPoints = 0;
            hotspotState = 'active';

            if(hotspot.length > 0) {
                document.getElementById("hotspot-image").style.width = width + 'px';
                document.getElementById("hotspot-target").style.width = width + 'px'
            }

            hotspot.find("#hotspot-incorrect").on('click', function() {
                $(this).fadeOut(100);
                activateScreen();
            });


            axios.get(firstImage).then(function(response) {
                $('#hotspot-game').show("fast", function() {
                    //prepare area
                    var height = hotspot.find("#hotspot-image").height() + 92;

                    wrapper.height(height)

                    //load target image
                    var a = document.getElementById("hotspot-target");

                    var svgDoc = a.contentDocument;

                    axios.get(targetPath).then(function(response) {
                        a.innerHTML = response.data;

                        //attach click listener

                        svg = a.getElementsByTagName("svg");

                        svg[0].addEventListener("click", checkClickedArea, false);

                        return svg[0].children.length
                    })
                        .then(function(response) {
                            var timeout = hotspot.data('timeout');
                            var timer = hotspot.find('.game-timer');
                            if(timeout > 0) {
                                var startTime = Date.now();

                                setInterval(function() {
                                    if(hotspotState == 'active') {
                                        var time = Date.now() - startTime;
                                        timer.text(formatForTimer(time, timeout));
                                        if(time > (timeout * 1000)) {
                                            var endMessage = hotspot.find('#timeout-message');
                                            endMessage.find('.hotspot-points').text(hotspotTotalPoints);
                                            endMessage.show();

                                            hotspot.find('#hotspot-total').val(hotspotTotalPoints);
                                            hotspot.find('#hotspot-target').hide();
                                            hotspot.find('#hotspot-image').fadeTo('fast', 0.5, function() {
                                                $('.task-buttons').show()
                                            });
                                            hotspotState = 'timedout'
                                        }
                                    }

                                }, 1000)
                            }
                        });
                });
            });
        });
    };
}(jQuery));
