//Jquery plugins
(function ($) {
    //Apply sequence to date fields so they are in order
    //Requires bootstrap datepicker
    $.fn.sequenced = function () {
        var self = this;
        self.elements = [];
        self.delay = 1; //Default to one day in between dates
        self.insequence = 1; //Make sure the dates are one after each other or not

        self.onChange = function (e) {
            var $dateElement = $(this);
            var date = $dateElement.val();

            var index = parseInt($dateElement.data('dateIndex'));
            if (self.elements[index + 1] && self.insequence) {
                //If we need to stay in sequence

                var $nextDate = $(self.elements[index + 1]); //Load next date in the list

                var minDate = addDays(date, self.minimumDelay);
                var recommendedDate = addDays(date, self.recommendedDelay);
                var oldDate = dateFromStr($nextDate.val());

                if (minDate.getTime() > oldDate.getTime()) {
                    $nextDate.datepicker('setDate', recommendedDate.ddmmyyyy());
                }
                $nextDate.datepicker('setStartDate', minDate.ddmmyyyy());
            }
        };

        function dateFromStr(str) {
            var parts = str.split('/');
            //Split date so we can use the format dd/mm/yyyy
            var result = new Date(parts[2], parts[1] - 1, parts[0]);
            return result;
        }

        //Convert to dd/mm/yyyy string
        Date.prototype.ddmmyyyy = function () {
            var yyyy = this.getFullYear();
            var mm = this.getMonth() < 9 ? "0" + (this.getMonth() + 1) : (this.getMonth() + 1); // getMonth() is zero-based
            var dd = this.getDate() < 10 ? "0" + this.getDate() : this.getDate();
            return "".concat(dd).concat('/').concat(mm).concat('/').concat(yyyy);
        };

        function addDays(date, days) {
            var result = dateFromStr(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        return this.each(function () {
            var $wrapper = $(this);
            if ($wrapper.data('min')) {
                self.minimumDelay = $wrapper.data('min');//Minimum allowable days between dates
            }
            if ($wrapper.data('recommended')) {
                self.recommendedDelay = $wrapper.data('recommended');//Days to space items
            }
            if ($wrapper.data('insequence')) {
                self.insequence = $wrapper.data('insequence');
            }
            var index = 0;
            //Find the date fields in the wrapper
            $wrapper.find('.sequenced-date').each(function () {
                var $dateElement = $(this),
                    skip = $dateElement.hasClass('skip');
                if (!skip) {
                    $dateElement.data('dateIndex', index);
                    $dateElement.on('change', self.onChange);
                    self.elements.push(this);
                    index++;
                }

                var min = $dateElement.prop('min');
                $dateElement.datepicker({
                    autoclose: true,
                    'startDate': min,
                    'format': 'dd/mm/yyyy'
                });

            });

            return this;
        });
    };

}(jQuery));