/**
 * Single series plugin for Highcharts
 * Author: Konstantin Lazutin
 * Version: 0.1.2 (2017-03-01)
 */


(function (H, $) {
    H.wrap(H.Chart.prototype, 'init', function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        var chart = this;
        separator = chart.legend.options.singleSeriesSeparator || getSeparator(chart);
        onlyWord = chart.legend.options.singleSeriesWord || "Only";
        if (chart.legend.options.singleSeriesEnabled) {
            if (!chart.legend.options.useHTML) {
                console.log('HTML has to be enabled for the SingleSeries plugin to work');
            } else {
                for (var i = 0; i < chart.legend.allItems.length; i++) {
                    chart.legend.allItems[i].singleSeriesName = chart.legend.allItems[i].name;
                    if (separator.indexOf('<br>') > 0) {
                        chart.legend.allItems[i].name += separator
                    } // reserve the second line of text in the legend to prevent it from jumping up and down
                    setEvents(i, chart);
                    chart.legend.render();
                }
            }
        }
    });

    function getSeparator(chart) {
        if (chart.legend.options.layout == 'horizontal') {
            return ' - '
        } else {
            return '&nbsp;'
        }
    }

    function setEvents(i, chart) {
        Highcharts.addEvent(chart.legend.allItems[i].legendItem.element, 'mouseenter', function () {
            setName(i, chart);
            chart.legend.render();
        }.bind(i));

        Highcharts.addEvent(chart.legend.allItems[i].legendItem.element, 'mouseleave', function () {
            removeName(i, chart);
            chart.legend.render();
        }.bind(i));
    }

    function removeEvents(i, chart) {
        Highcharts.removeEvent(chart.legend.allItems[i].legendItem.element, 'mouseenter');
        Highcharts.removeEvent(chart.legend.allItems[i].legendItem.element, 'mouseleave');
    }

    function setName(i, chart) {
        chart.legend.allItems[i].name = chart.legend.allItems[i].singleSeriesName + separator + '<a onclick="processLegendItem(' + i + ',\'' + chart.renderTo.id + '\')">' + onlyWord + '</a>';
    }

    function removeName(i, chart) {
        separator.indexOf('<br>') > -1
            ? chart.legend.allItems[i].name = chart.legend.allItems[i].singleSeriesName + separator
            : chart.legend.allItems[i].name = chart.legend.allItems[i].singleSeriesName;
    }

    function processLegendItem(index, chartId) {
        var chart = $("#" + chartId).highcharts();
        event.stopPropagation();
        if (!chart.legend.allItems[index].onlySet) {
            for (var i = 0; i < chart.legend.allItems.length; i++) {
                if (i == index) {
                    chart.legend.allItems[i].onlySet = true;
                    chart.series[i].setVisible(true, false);
                    setName(i, chart);
                    removeEvents(i, chart);
                } else {
                    chart.legend.allItems[i].onlySet = false;
                    chart.series[i].setVisible(false, false);
                    removeName(i, chart);
                    setEvents(i, chart);
                }
            }
        } else {
            for (var i = 0; i < chart.legend.allItems.length; i++) {
                chart.legend.allItems[i].onlySet = false;
                chart.series[i].show();
                removeName(i, chart);
                setEvents(i, chart);
            }
        }
        chart.legend.render();
        chart.redraw();
    }

    window.processLegendItem = processLegendItem;
}(Highcharts, jQuery));

