
//Jquery plugins
(function ($) {

    $.fn.bindTo = function (boundSelector) {
        var self = this;
        self.boundSelector = boundSelector;


        function applyValue(event) {
            var $this = $(this);
            var type = $this.prop('type');
            var boundItems = $(this).parents('.bound').first().find(self.boundSelector);
            if (type == 'checkbox' || type == 'radio') {
                if (this.checked) {
                    boundItems.prop('checked', true);
                } else {
                    boundItems.removeAttr('checked');
                }
            }
            if (type == 'date' || type == 'text' || type == 'select-one') {
                boundItems.val($this.val());
            }
            boundItems.trigger('change');
        }

        this.each(function () {

            $(this).change(applyValue);
            // $(this).keyUp(applyValue);
        });
        return this;
    };

}(jQuery));