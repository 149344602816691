//Jquery plugins
(function($) {


    $.fn.softRequire = function(formValid) {
        var formValid = formValid;
        var $modal = $('<div class="modal fade" id="">' +
            '<div class="modal-dialog">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>' +
            '<h4 class="modal-title">Are you sure you want to continue without answering these questions?</h4>' +
            '</div>' +
            '<div class="modal-body soft-require">' +
            '<div class="modal-list"></div>' +
            '</div>' +
            '<div class="modal-footer">' +
            '<button type="button" class="btn btn-default" data-dismiss="modal">' +
            '<i class="far fa-times"></i> No</button>' +
            '<button type="submit" data-loading-text="<i class=\'far fa-spinner-third fa-spin-fast\'></i> " class="btn btn-success"><i class="far fa-check"></i> Yes</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>');
        var $form;

        function setModalContent(content) {
            $modal.find('.modal-body,button,[type=button],input')
                .removeClass('disabled');
            $modal.css('pointer-events', '');

            $modal.find('.modal-list').html(content);

        }


        function onSubmitPress(e) {

            var $fields = $form
                .find('[data-soft-required]')
                .filter(function() {
                    var $this = $(this);
                    if($this.hasClass('changed')) return false;
                    if($this.hasClass('no-validation')) return false;
                    if($this.has('.no-validation').length > 0) return false;
                    var $input = $this.find('.question-value').first();
                    if($input.length === 0)
                        $input = $this.find('textarea,select,[type=text],[type=date],[type=radio],[type=checkbox],[type=number],[type=number],.drawing-image').first();
                    if($input.attr('disabled') == true) {
                        return false;
                    }
                    if($input.hasClass('no-validation')) return false;

                    var min = $input.data('minimum');
                    if(min) {
                        return parseFloat($input.val()) < parseFloat(min);
                    }

                    return !$input.val() && $(this).has(':checked:not(.no-validation)').length === 0;
                });
            if($fields.length > 0) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                var ul = $('<ul/>');
                $fields.each(function() {
                    var li = $('<li/>');
                    li.text($(this).find('.panel-title,.question-text').first().text());
                    ul.append(li);
                });
                setModalContent(ul);
                $modal.modal('show');

                return false;
            }

            return formValid();
        }

        this.each(function() {
            $form = $(this);
            $modal.appendTo(this);
            $modal = $modal.modal({backdrop: true, show: false});
            $modal.find('button[type=submit]').on('click', function() {
                var $button = $(this);
                $button.width($button.width());
                $button.height($button.height());
                $button.html('<i class=\'far fa-spinner-third fa-spin-fast\'></i>');

                $modal.find('.modal-body,button,[type=button],input')
                    .addClass('disabled');
                $modal.css('pointer-events', 'none');

            });
            $(".task-buttons button[type=submit]", this).on('click', onSubmitPress);
        });

        return this;
    };


}(jQuery));
