(function ($) {
    $.fn.scaleSlider = function () {
        return this.each(function () {
            var $scale = $(this);
            var scaleTicks = [];
            var min = $scale.attr('scale-min');
            var max = $scale.attr('scale-max');
            var value = $scale.val();

            for (var i = min; i <= max; i++) {
                scaleTicks.push(i);
            }

            var scaleSlider = $scale.bootstrapSlider({
                ticks: scaleTicks,
                ticks_labels: scaleTicks,
                step: 1,
                value: parseInt(value),
                tooltip: 'hide'
            });
            return this;
        });
    };

}(jQuery));
