(function ($) {


    $.fn.helpNotes = function (config) {

        var defaults = {
            noteSelector: '.help-note',
            startHidden: false
        };
        var self = {};
        self.config = $.extend(config, defaults);
        self.hidden = self.config.startHidden;

        function apply() {
            if (self.hidden) {
                $(self.config.noteSelector).hide(400);
                $('body').removeClass('show-help');
            } else {
                $(self.config.noteSelector).show(400);
                $('body').addClass('show-help');
            }
        }

        function toggle() {
            self.hidden = !self.hidden;
            apply();
        }

        apply();
        return this.each(function () {
            var $this = $(this);
            $this.on('click', toggle);
            return this;
        });

    };


}(jQuery));