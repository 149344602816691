//Jquery plugin
(function ($) {

    $.fn.repeatableQuestion = function (callback) {

        this.each(function () {
            var $self = $(this),
                options = {
                    min: $self.data('min'),
                    max: $self.data('max'),
                    template: $self.data('template'),
                    labelInterval: $self.data('label-interval'),
                    headerTemplate: $self.data('header-template'),
                },
                rowTemplate = $(options.template).html(),
                headerTemplate = $(options.headerTemplate).html(),
                $wrapper = $self.find('.repeatable-content').first();
            $self.find('.btn-add-row').on('click', addRow);
            $wrapper.on('click', '.btn-remove-row', removeRow);
            disableIfNeeded();
            updateHeaders();

            function disableAddButton() {
                $self.find('.btn-add-row').addClass('disabled');
            }

            function enableAddButton() {
                $self.find('.btn-add-row').removeClass('disabled');
            }

            function updateHeadersWithTemplate() {
                $self.find('.repeating-label').remove();
                $self.find('.repeatable-row').each(function (index, row) {
                    if (index == 0 || index % options.labelInterval == 0) {
                        $(row).before(headerTemplate);
                    }
                })
            }

            function updateHeaders() {
                if (!options.labelInterval) return;
                if (options.headerTemplate) {
                    return updateHeadersWithTemplate();
                }
                $self.find('.repeatable-row').each(function (index, row) {
                    var $row = $(row);
                    if (index == 0 || index % options.labelInterval == 0) {
                        $row.removeClass('hide-headers');
                    } else {
                        $row.addClass('hide-headers');
                    }
                })
            }

            function removeRow() {
                var $row = $(this).closest('.repeatable-row');
                if ($row.prev().hasClass('repeating-label')) {
                    $row.prev().remove();
                }
                $row.remove();
                updateHeaders()
                enableAddButton();
            }

            function disableIfNeeded() {
                var rowCount = $wrapper.find('.repeatable-row').length;
                if (options.max > 0 && rowCount >= options.max) {
                    disableAddButton();
                }
            }

            function addRow() {
                var rowCount = $wrapper.find('.repeatable-row').length;
                if (options.max > 0 && rowCount > options.max) {
                    return;
                }
                var newRow = rowTemplate.replace(/\{\{occurrence\}\}/g, rowCount + 1);
                $wrapper.find('.repeatable-body').append(newRow);
                updateHeaders();

                disableIfNeeded();
                if (typeof callback === 'function') {
                    callback($self);
                }
            }
        });

        return this;
    };


}(jQuery));