//Jquery plugin
(function ($) {

    $.fn.gridQuestion = function () {

        this.each(function () {
            var $self = $(this);

            function onClick() {
                var $this = $(this);
                $this.parents('tr').find('td.grid-question-input').removeClass('active');
                $this.addClass('active').find('input').prop('checked', true);
            }

            $self.find('td.grid-question-input').on('click', onClick);
            $self.find('.grid-question-input input').each(function () {
                if (this.checked) {
                    $(this).parents('td.grid-question-input').addClass('active');
                } else {
                    $(this).parents('td.grid-question-input').removeClass('active');
                }
            });

        });

        return this;
    };


}(jQuery));