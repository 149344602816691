//Jquery plugin
(function($) {

    $.fn.ajaxForm = function() {
        var $submitButton, $form;

        function onSuccess(data, status, xhr) {
            var location = xhr.getResponseHeader('turbolinks-location');
            var snapshot = window.Turbolinks.Snapshot.wrap(data);

            if(!location) {
                location = window.location.href;
            }
            $('body').removeClass('modal-open')
                .removeClass('form-loading');
            $('.modal-backdrop').remove();
            window.Turbolinks.controller.cache.put(location, snapshot);
            window.Turbolinks.visit(location, {action: 'restore'});
            Turbolinks.clearCache();
            if(window.Turbolinks.controller.adapter.hideProgressBar) {
                window.Turbolinks.controller.adapter.hideProgressBar();
            }
        }

        function loadingStateOn() {

            $submitButton.button('loading');
            $form.find('form,a,button,[type=button],input,.btn-group')
                .addClass('disabled');
            $form.css('pointer-events', 'none');
        }

        function loadingStateOff() {
            $submitButton.button('reset');
            $form.find('form,a,button,[type=button],input,.btn-group')
                .removeClass('disabled');
            $form.css('pointer-events', 'inherit');
        }

        function setupSubmitButton() {
            $submitButton = $form.find('button[type=submit]');
            $submitButton.each(function() {
                $(this).data('loading-text', '<i class="far fa-spinner-third fa-spin-fast"></i> ' + $(this).text());
            });
        }

        function onSubmit(e) {
            $form = $(this);
            setupSubmitButton();
            e.preventDefault();
            if(window.Turbolinks.controller.adapter.showProgressBarAfterDelay) {
                window.Turbolinks.controller.adapter.showProgressBarAfterDelay();
            }
            loadingStateOn();
            var data = $form.serialize();
            var method = $form.prop('method'), action = $form.prop('action');
            if(method == 'get') {
                Turbolinks.visit(action + data);
                loadingStateOff();
                return false;
            }
            $.ajax({
                url: action,
                headers: {'Turbolinks-Referrer': window.location},
                method: $form.prop('method'),
                data: data,
                success: onSuccess,
                error: function(data, status, xhr) {
                    loadingStateOff()
                }
            });

        }

        this.each(function() {
            $(this).on('submit', onSubmit);
        });

        return this;
    };


}(jQuery));
