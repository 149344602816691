//Jquery plugin
(function($) {

    $.fn.drawingQuestion = function() {

        this.each(function() {
            var $self = $(this);

            function reset() {
                $self.removeClass('readonly');
                $self.find('.drawing-previous').css('background-image', '');

                $self.find('input[name=drawing-new]').val("1");
            }

            var defaultColor = $self.data('color');
            if(!defaultColor) {
                defaultColor = '#000';
            }
            var defaultSize = $self.data('size');
            if(!defaultSize) {
                defaultSize = 5;
            }
            $self.onStopPainting = function(e) {
                var sketch = $self.find('.drawing-canvas').data('sketch');

                var theImage = sketch.download('png');
                $self.addClass('touched');
                $self.find('.drawing-image').val(theImage);
            }
            $self.find('.drawing-canvas').sketch({
                toolLinks: true,
                defaultSize: defaultSize,
                defaultColor: defaultColor
            }).on('sketch.stopPainting', $self.onStopPainting);
            $self.find('.btn-clear').on('click', function(e) {
                e.preventDefault();
                reset();
            });
            $self.find('.btn-file input').on('change', function(e) {
                var img = new Image();
                img.onload = drawImage;
                img.onerror = imageError;
                img.src = URL.createObjectURL(this.files[0]);
            });

            function imageError() {
                alert("The provided file couldn't be loaded as an Image");
            }

            function drawImage() {
                var canvas = $self.find('.drawing-canvas');
                var ctx = canvas[0].getContext('2d');
                ctx.drawImage(this, 0, 0, canvas.width(), canvas.height());
                var sketch = canvas.data('sketch');

                var theImage = sketch.download('png');
                $self.addClass('touched readonly');
                $self.find('.drawing-previous').css('background-image', "url('" + this.src + "')");
                $self.find('.drawing-image').val(theImage);
                $self.find('input[name=drawing-new]').val("1");
            }

            $self.find('.drawing-previous').on('mousedown', function(e) {
                if(confirm('Do you want to clear the previous image and start again?')) {
                    reset();
                }
            });

            $(document).on('beforeSubmitQuestions', function() {
                var $isNew = $self.find('input[name=drawing-new]').val();
                var sketch = $self.find('.drawing-canvas').data('sketch');
                if($isNew === "1" && sketch.answered) {
                    $self.onStopPainting();
                }
            });
        });

        return this;
    };


}(jQuery));
