//Jquery plugins
(function ($) {


    $.fn.toggleVis = function () {
        function applyToggle(item) {
            if (item.checked) {
                $(item).parents('.toggle').first().find('.toggle-item').show();
            } else {
                $(item).parents('.toggle').first().find('.toggle-item').hide();
            }
        }

        this.each(function () {
            applyToggle(this);
            $(this).change(function (e) {
                applyToggle(this);
            });
        });
        return this;
    };


}(jQuery));