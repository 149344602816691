//Jquery plugin
(function($) {

    $.fn.instantPoll = function() {

        this.each(function() {
            //Setup chart with current answer values

            function makePieData(addPoint) {
                var data = [];
                for(var i = 0; i < labels.length; i++) {
                    var value = values[i];
                    if(i === addPoint) {
                        value = value + 1;
                    }
                    data.push({
                        name: labels[i],
                        y: value
                    })
                }
                return data;
            }

            function makeBarData(addPoint) {
                var barData = [];
                for(var i = 0; i < labels.length; i++) {
                    var value = values[i];
                    if(i === addPoint) {
                        value = value + 1;
                    }
                    barData.push({
                        name: labels[i],
                        data: [value]
                    })
                }
                return barData;
            }

            var $self = $(this);

            var chartElement = $self.find('.instant-poll-chart').first();
            var optionsElement = $self.find('.instant-poll-options').first();
            if(!chartElement || !$self.data('labels') || !$self.data('values')) {
                return false;
            }

            var labels = JSON.parse(decodeURIComponent($self.data('labels').replace(/\+/g, ' ')));
            var values = JSON.parse(decodeURIComponent($self.data('values').replace(/\+/g, ' ')));
            var title = $self.data('title');
            var use3d = $self.data('use3d');
            var chartType = $self.data('type');
            var series;
            if(chartType === 'pie') {
                series = [{
                    name: 'Responses',
                    colorByPoint: true,
                    data: makePieData()
                }];
            }
            if(chartType === 'bar' || chartType === 'column') {
                series = makeBarData();
            }
            var options3d = {};
            if(use3d) {
                if(chartType === 'pie') {
                    options3d = {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    };
                } else {
                    options3d = {
                        enabled: true,
                        alpha: 15,
                        beta: 15,
                        depth: 50,
                        viewDistance: 25
                    };
                }

            }


            $self.chart = new Highcharts.Chart(chartElement[0], {
                credits: {
                    enabled: false
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: chartType,
                    options3d: options3d
                },
                title: {
                    text: title
                },
                plotOptions: {
                    bar: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        depth: 35,
                    },
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        depth: 35,
                    },
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                xAxis: {
                    categories: ['Responses'],
                    crosshair: true
                },
                series: series
            });

            //Assign on click to options
            function onClick(event) {
                //Add clicked option to chart values
                console.log(event, series);
                var optionTitle = this.title;
                var index = labels.indexOf(optionTitle);
                if(chartType === 'pie') {
                    $self.chart.series[0].setData(makePieData(index))
                }
                if(chartType === 'bar' || chartType === 'column') {
                    $self.chart.update({
                        series: makeBarData(index)
                    });
                }
                //Hide options
                optionsElement.hide();
                //Show chart
                chartElement.show();
            }

            $self.find('.instant-poll-options input').change(onClick);
        });


        return this;
    };


}(jQuery));
