(function() {
    window.codeEditors = function(context) {
        if(!context) {
            context = document;
        }
        if(typeof ace !== "undefined") {
            var editors = context.getElementsByClassName('code-editor');
            if(editors) {
                for(var index in editors) {
                    var elem = editors[index];
                    var editor = ace.edit(elem.id, {
                        height: '100%',
                        showGutter: false,
                        showLineNumbers: false,
                        minLines: 4,
                        maxLines: 15,
                        mode: "ace/mode/twig"
                    });
                }
            }
        }
    }
}());
